<div class="d-flex pb-3 ">
    <span class="fs-2 lh-1">
        <span *ngIf="type == 'about'">
            About Us
        </span>
        <span *ngIf="type == 'internet'">
            Internet Privacy Policy
        </span>
        <span *ngIf="type == 'hipaa'">
            HIPAA Privacy & Security
        </span>
    </span>
    <button type="button" class="btn-close ms-auto" aria-label="Close" mat-dialog-close></button>

</div>
<mat-dialog-content>
    <div class="card p-3">
        <div *ngIf="type == 'about'">
            <p><b class="color-blue">QuantumLeap Global Solutions</b> is a part of <b class="color-blue">V Transcription and CannonRad, LLC,</b> 
                a medical transcription and radiology RIS/PACS provider serving the healthcare industry for the last 15 years.
                Our front desk application is a simple yet powerful tool to manage all of the front desk activities.  Added services:  <b class="color-blue">Data Entry Solution</b> and <b class="color-blue">Back Office Support</b>.
                 </p>
            <p>For more details and demo, please contact</p>
            <p><b>Email : </b> <a href="mailto:info@vtranscriptions.com">info@vtranscriptions.com</a> </p>
            <p><b>Phone : </b> <a href="tel:+16625504546">(662)-550-4546</a> </p>
        </div>
        <div *ngIf="type == 'internet'">
            <p>
                QuantumLeap Global Solutions is committed to protecting your privacy and providing you with a powerful and safe online environment. This notice is designed to inform you our privacy practices and to demonstrate our commitment to the privacy of our website visitors and their information. QuantumLeap Global Solutions does not collect nor share any personally identifiable information about visitors to the website. VTranscription has implemented and maintains physical, administrative and electronic security measures with regard to our site that are designed to safeguard your information from improper access or use. When collecting, using or transmitting sensitive electronic information, we encrypt the transmission using SSL or other similar technology.
            </p>
        </div>
        <div *ngIf="type == 'hipaa'">
            <p>
                The Health Insurance Portability and Accountability Act (HIPAA) provides federal regulations for the privacy and security of patients' health information. HIPAA legislation includes two major provisions that govern the safeguarding of patient information. HIPAA includes provisions designed to encourage electronic transactions, but also requires safeguards to protect the security and confidentiality of health information. Most hospitals, health insurers, pharmacies, doctors and other health care providers are required to comply with these federal standards. The privacy regulations ensure a national standard of privacy protections for patients by limiting the ways that health plans, pharmacies, hospitals and other covered entities can use patients' personal medical information. The regulations protect medical records and other individually identifiable health information, whether on paper, in computers or communicated orally. BPA is committed to meeting or surpassing the standards set forth in these regulations.
            </p>
        </div>
    </div>


</mat-dialog-content>