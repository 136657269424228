import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";
import 'moment-timezone';

@Pipe({
  name: 'dateAutoRegion'
})
export class DateAutoRegionPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return value == null || value == "" ? null : moment.tz(value, moment.tz.guess()).format("DD/MM/YYYY hh:mm A");
  }

}
