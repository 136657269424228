import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroupDirective, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NetworkCallsService } from "../../sharedservices/network-calls.service";
import {Message,MessageService} from 'primeng/api';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  providers: [MessageService]
})
export class CommentsComponent implements OnInit {
  @Input('ticketId') ticketId:number;
  @Output() commentCreated = new EventEmitter();
  commentsData:any = [];
  loading = false;
  commentForm:FormGroup
  msgs: Message[];

  constructor(
    private fb: FormBuilder,
    public networkCallsService: NetworkCallsService,
    private messageService: MessageService,

  ) { }

  ngOnInit(): void {
    this.getComments();
    this.createForm();
  }

  createForm(){
    this.commentForm = this.fb.group({
      comment: ['', Validators.required],
      ticket_id:[this.ticketId, Validators.required]
    });
  }

  getComments() {
    if(typeof(this.ticketId) == 'string'){
      return;
    }
    this.loading = true;
    this.commentsData = [];
    var url = "api/comments/" + this.ticketId;
    var serviceResponse: any = this.networkCallsService.get(url);
    serviceResponse.subscribe(
      (data: any) => {
        this.commentsData = data;
        this.loading = false;
      },
      (error:any) => {
        this.loading = false;
        // console.log(error.error.errors[0].msg);
        // console.clear();
      }
      // () => // console.log("Finished")
    );
  }

  submitForm(myForm:FormGroupDirective){
    console.log(this.commentForm.value)
    if(this.commentForm.invalid){
      return;
    }

    this.messageService.clear();
    this.loading = true;
    var url = "api/comments/create";
    var serviceResponse: any = this.networkCallsService.post(
      url,
      JSON.stringify(this.commentForm.value)
    );
    serviceResponse.subscribe(
      (data:any) => {
        this.loading = false;
        if (data.success) {
          this.commentForm.reset();
          myForm.resetForm();
          this.commentForm.patchValue({ticket_id:this.ticketId})
          this.messageService.add({severity:'success', summary:'Success!', detail:data.message});
          this.getComments();
          this.commentCreated.emit('created');
        }
      },
      (error:any) => {
        this.loading = false;
        if(error.error.errors && Object.keys(error.error.errors).length > 0){
          var errorObject = error.error.errors;
          for (const key in errorObject) {
            errorObject[key].forEach((element: any) => {
              this.messageService.add({severity:'error', summary: 'Error: ' + key + '!', detail:element});
            });
          }
        }else{
          this.messageService.add({severity:'error', summary:'Error!', detail:error.error.message});
        }

      }
      // () => // console.log("Finished")
    );
  }



}
