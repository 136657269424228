import { Component, OnInit, Inject } from "@angular/core";
import { NetworkCallsService } from "../network-calls.service";
import { Router } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogData {
  url: string;
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public networkCallsService: NetworkCallsService,
    public router: Router
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.logout();
    }, 5000);
  }

  logout() {
    this.loading = true;
    var url = "api/logout";
    let body = new FormData();
    var serviceResponse: any = this.networkCallsService.post(url, body);
    serviceResponse.subscribe(
      (data: { message: string; }) => {
        if (data.message == "Logged out Successfully.") {
          localStorage.removeItem("authTokenRMS");
          localStorage.removeItem("user");
          var link = ["/login"];
          this.router.navigate(link);
          this.dialogRef.close();
        }
      },
      (error: { status: number; }) => {
        if(error.status == 401){
          localStorage.removeItem("authTokenRMS");
          localStorage.removeItem("user");
          var link = ["/login"];
          this.router.navigate(link);
          this.dialogRef.close();
        }
      }
      // () => // console.log("Finished")
    );
  }

}
