import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';


import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatDialogModule } from "@angular/material/dialog";
import { AuthGuardService } from "./auth-guard.service";
import { NetworkCallsService } from "./network-calls.service";
import { RequestInterceptorService } from "./request-interceptor.service";
import { LoadingService } from './loading.service';
import { ErrorComponent } from './error/error.component';

@NgModule({
  declarations: [
    ErrorComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  providers: [
    AuthGuardService,
    NetworkCallsService,
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
  ],
  entryComponents: [ErrorComponent],
})
export class SharedservicesModule { 
  static forRoot(): ModuleWithProviders<SharedservicesModule> {
    return {
      ngModule: SharedservicesModule,
      providers: [AuthGuardService, NetworkCallsService, LoadingService],
    };
  }
}
