import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsComponent } from './comments/comments.component';
import {CommonLibModule} from '../../../../common-lib/src/lib/common-lib.module';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MessagesModule } from "primeng/messages";
import { LoadingComponent } from './loading/loading.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { TicketStatusComponent } from './ticket-status/ticket-status.component';
import { TooltipModule } from 'primeng/tooltip';
import { MatDialogModule } from "@angular/material/dialog";


@NgModule({
  declarations: [
    CommentsComponent,
    LoadingComponent,
    TicketStatusComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    CommonLibModule,
    FormsModule, ReactiveFormsModule,
    MessagesModule,
    MatDialogModule,
    MatProgressBarModule
  ],
  entryComponents:[
  ],
  exports: [
    CommentsComponent,
    LoadingComponent,
    TicketStatusComponent
  ]
})
export class SharedcomponentsModule { }
