import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable } from "rxjs";
import {tap, finalize} from 'rxjs/operators';
import { ErrorComponent } from './error/error.component';
import { MatDialog } from "@angular/material/dialog";
import { LoadingService } from "./loading.service";
@Injectable({
  providedIn: "root",
})
export class RequestInterceptorService implements HttpInterceptor {

  constructor(
    public dialog: MatDialog,
    private loadingService: LoadingService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.show();
    return next.handle(request).pipe(
      finalize(() => this.loadingService.hide()),
      tap(() => {
      
      },
      (err: any) => {
      
      if (err instanceof HttpErrorResponse) {
        if (err.status !== 401) {
         return;
        }
        if (!request.url.includes("logout") && this.dialog.openDialogs.length == 0) {
          this.dialog.open(ErrorComponent, {
            width: "500px",
            disableClose: true,
            closeOnNavigation: false,
            data:{url: request.url},
            autoFocus: false,
          });
        }
      }
      this.loadingService.hide();
    }));
  }

}