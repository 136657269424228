import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  ActivatedRoute,
} from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public router: Router,
    public _location: Location,
    public activatedRoute: ActivatedRoute
  ) {}

  canActivate(next: ActivatedRouteSnapshot) {
    var authToken = localStorage.getItem('authTokenRMS');
    var userJson = JSON.parse(localStorage.getItem('user'));

    // switch (next.routeConfig.path) {
    switch (next.url[0].path) {
      
      case 'login':
        if (authToken != null && authToken != '' && authToken.length > 0) {
          this.router.navigate(['/home']);
        } else {
          return true;
        }
        break;

      case 'home':
        if (authToken == null || authToken == '' || authToken.length == 0) {
          this.router.navigate(['/login']);
        } else {
          return true;
        }
        break;

      case 'admin':
        if (userJson.role_detail.id != 1) {
          this.router.navigate(['/home']);
        } else {
          return true;
        }
        break;

      case 'appointments':
        if (!userJson.role_detail['appointments_read']) {
          this.router.navigate(['/home']);
        } else {
          return true;
        }
        break;

      case 'refill-requests':
        if (!userJson.role_detail['refill_requests_read']) {
          this.router.navigate(['/home']);
        } else {
          return true;
        }
        break;

      case 'billing-queries':
        if (!userJson.role_detail['billing_queries_read']) {
          this.router.navigate(['/home']);
        } else {
          return true;
        }
        break;

      case 'note-to-doctor':
        if (!userJson.role_detail['note_to_doctors_read']) {
          this.router.navigate(['/home']);
        } else {
          return true;
        }
        break;

      case 'general-inquiry':
        if (!userJson.role_detail['general_inquiries_read']) {
          this.router.navigate(['/home']);
        } else {
          return true;
        }
        break;

      case 'report-requests':
        if (!userJson.role_detail['report_requests_read']) {
          this.router.navigate(['/home']);
        } else {
          return true;
        }
        break;
    }

    return false;
  }
}
