import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-status',
  templateUrl: './ticket-status.component.html',
  styleUrls: ['./ticket-status.component.scss']
})
export class TicketStatusComponent implements OnInit {
  @Input('ticketStatus') ticketStatus: string;


  constructor(   
  ) { }

  ngOnInit(): void {
  }

}
