import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonLibComponent } from './common-lib.component';
import { InformationComponent } from './information/information.component';
import { BackButtonDirective } from './back-button.directive';
import { DateAutoRegionPipe } from './date-auto-region.pipe';


@NgModule({
  declarations: [
    CommonLibComponent,
    InformationComponent,
    BackButtonDirective,
    DateAutoRegionPipe
  ],
  imports: [
    MatDialogModule,
    CommonModule
  ],
  exports: [
    CommonLibComponent,
    InformationComponent,
    BackButtonDirective,
    DateAutoRegionPipe
  ],
  entryComponents:[
    InformationComponent
  ]
})
export class CommonLibModule { }
