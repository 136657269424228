<div class="d-block position-relative">
    <div *ngIf="loading" class="loading-container">
        <div class="lds-ripple animate__fadeIn animate__animated">
            <div></div>
            <div></div>
        </div>
    </div>
    <mat-dialog-content>
        <h1 class="fs5 mt-5 mb-2 text-center color-pink">
            401 : Unauthorized!
        </h1>
        <p class="text-center mb-5">You will be logged out in 5 seconds.</p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button class="btn btn-primary mr-2" (click)="logout()"><i class="fas fa-power-off mr-2"></i> Logout Now</button>
    </mat-dialog-actions>
</div>