<div class="d-block position-relative">

    <div class="row g-3 mb-3">
        <div *ngIf="commentsData.length == 0" class="col-12">
            <div class="card p-3">
                No Comments yet!
            </div>
        </div>
        <div *ngFor="let item of commentsData" class="col-12">
            <div class="card p-3">
                <div class="d-flex">
                    <div class="flex-shrink-0">
                        <i class="fa fa-comment color-pink" aria-hidden="true"></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <div class="d-flex align-items-center mb-2">
                            <h6 class="m-0">{{item.created_by}}</h6>
                            <small class="ms-auto color-medium-grey">{{item.created_at | dateAutoRegion}}</small>
                        </div>
                        {{item.comment}}
                    </div>
                </div>
            </div>
        </div>

    </div>
    <form (ngSubmit)="submitForm(myform)" [formGroup]="commentForm" #myform="ngForm" novalidate>
        <p-messages [(value)]="msgs"></p-messages>
        <div class="d-block mb-3">
                <div class="row g-2">
                    <div class="col-sm-12 position-relative">
                        <textarea class="form-control" id="comment" placeholder="Type your comment and submit" formControlName="comment" [ngClass]="{ 'is-invalid': commentForm.get('comment').invalid  && (commentForm.get('comment').dirty || commentForm.get('comment').touched || myform.submitted), 'is-valid': commentForm.get('comment').valid  && (commentForm.get('comment').dirty || commentForm.get('comment').touched) }" rows="3"></textarea>
                        <div *ngIf="commentForm.get('comment').invalid  && (commentForm.get('comment').dirty || commentForm.get('comment').touched || myform.submitted)" class="invalid-feedback">
                            <div *ngIf="commentForm.get('comment').hasError('required')">Comment is required.</div>
                        </div>
                    </div>
                </div>
        </div>
        <div class="d-flex align-items-center">
            <div class="ms-auto">
                <button type="submit" class="btn btn-primary">Submit Comment</button>
            </div>
        </div>
    </form>
</div>
